import React, { useEffect, useState } from "react";
import logo from "./logo.png";
import compass from "./compass.png";
import oranges from "./oranges.png";
import seekers from "./seekers.png";
import { Helmet } from "react-helmet";
import Spinner from "./Spinner";
import RadioButton from "./RadioButton";

function App() {
  const [page, setPage] = useState("start");
  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState({
    name: "",
    email: "",
    school: "",
    about: "",
    how: "",
    agree: false,
  });

  console.log(values.agree);

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    school: false,
    about: false,
    how: false,
    agree: false,
  });

  const handleChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const formatEmail = (v) => {
    let email = "";
    for (const [key, value] of Object.entries(values)) {
      email = email.concat(`<h1>${key}: ${value}</h1>`);
    }
    return email;
  };

  const handleSubmit = () => {
    setLoading(true);
    if (Object.values(values).every(Boolean) && values.how !== "NA") {
      window.Email.send({
        SecureToken: "29b06643-50e2-4725-8e5c-7df6c59e7433",
        Username: "no-reply@cm2.co.uk",
        To: "hello@cm2.co.uk",
        From: "no-reply@cm2.co.uk",
        Subject: `CHOOSE YOUR INDUSTRY APPLICATION`,
        Body: formatEmail(values),
      }).then((message) => {
        if (message !== "OK") {
          alert(
            "Sorry, something went wrong on our end. Please try again later."
          );
          setPage("start");
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        } else {
          setLoading(false);
          setPage("success");
        }
      });
    } else {
      for (const [key, value] of Object.entries(values)) {
        console.log(key);
        if (value === "" || value === "NA" || value === false) {
          setErrors((prev) => ({
            ...prev,
            [key]: true,
          }));
        } else {
          setErrors((prev) => ({
            ...prev,
            [key]: false,
          }));
        }
      }
      setLoading(false);
    }
  };
  console.log(
    errors,
    Object.values(errors).some((v) => v)
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  return (
    <div className="app">
      <Helmet>
        <title>CM2: Choose Your Industry</title>
        <meta
          name="description"
          content="Tell ConnecMe2 what your dream job looks like and let us handle the rest. Choosing the industry of your future made easy."
        />
        <link rel="icon" type="image/png" href="favicon.ico" />
        <script src="https://smtpjs.com/v3/smtp.js"></script>
      </Helmet>
      <header>
        <a
          href="https://connecme2.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={logo} alt="logo" className="logo" />
        </a>
        <h2>Choose Your Industry</h2>
        <div>
          <a
            href="https://connecme2.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={oranges} alt="oranges" />
          </a>
          <a
            href="https://connecme2.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={compass} alt="compass" className="compass" />
          </a>
        </div>
      </header>

      {page === "success" && !loading ? (
        <h1>
          Thanks for your interest in ConnecMe2's{" "}
          <span className="bold">Choose Your Industry </span>project!
        </h1>
      ) : (
        <h1>
          You are applying to take part in the ConnecMe2{" "}
          <span className="bold">Choose Your Industry </span>
          project
        </h1>
      )}
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className={page === "start" ? "start is--active" : "start"}>
            <iframe
              width="790"
              height="444"
              src="https://www.youtube.com/embed/i3zqcQIM-cc"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <h2>
              Thanks for choosing ConnecMe2. Before we begin, watch this short
              video to see why our people love this field of work!
            </h2>
            <h2>
              To start with your destination is the most important thing, to get
              to your destination is where we come in. We just want to know your
              dream job, dream role or dream industry, and we will use our
              skills, knowledge and connections to help you gain an experience
              that will get you that one step closer to that dream!
            </h2>
            <h2>
              If you need any additional support, or some adjustments in this
              process or there is a general problem, please contact our
              ‘Community Help Desk’ at{" "}
              <a href="mailto:hello@cm2.co.uk" className="hover-link">
                hello@cm2.co.uk
              </a>
            </h2>
            <h2>Here are a few tips:</h2>
            <ul>
              <li>
                We recommend using a strong Wi-Fi to watch the video without any
                problems. If you’re using mobile data, please be aware of any
                data allowances or restrictions on your device
              </li>
              <li>
                Allow emails from ConnecMe2 in your Settings. Also check your
                junk email, just in case
              </li>
            </ul>
            <h2>When you’re ready, click 'Get started'.</h2>
            <button className="cta-button" onClick={() => setPage("form")}>
              Get Started
            </button>
          </div>
          <div className={page === "form" ? "form is--active" : "form"}>
            <label htmlFor="name">
              <span
                className={errors.name ? "error-msg is--active" : "error-msg"}
              >
                *{" "}
              </span>
              Your name:
            </label>
            <input
              className={errors.name ? "--error" : ""}
              name="name"
              type="text"
              placeholder="name..."
              onChange={handleChange}
            />
            <label htmlFor="contact details">
              <span
                className={errors.email ? "error-msg is--active" : "error-msg"}
              >
                *{" "}
              </span>
              Your email:
            </label>
            <input
              className={errors.email ? "--error" : ""}
              name="email"
              type="text"
              placeholder="email..."
              onChange={handleChange}
            />
            <label htmlFor="school">
              <span
                className={errors.school ? "error-msg is--active" : "error-msg"}
              >
                *{" "}
              </span>
              Your educational institution and location:
            </label>
            <input
              className={errors.school ? "--error" : ""}
              name="school"
              type="text"
              placeholder="school..."
              onChange={handleChange}
            />
            <label htmlFor="about">
              <span
                className={errors.about ? "error-msg is--active" : "error-msg"}
              >
                *{" "}
              </span>
              What industry or sector would you like to work in, and what role
              would you like to have in that industry? Have you had any work
              experience that relates to this
            </label>
            <textarea
              className={errors.about ? "--error" : ""}
              name="about"
              type="text"
              placeholder="Choose your industry..."
              onChange={handleChange}
            />
            <label htmlFor="how">
              <span
                className={errors.how ? "error-msg is--active" : "error-msg"}
              >
                *{" "}
              </span>
              How did you hear about this project?
            </label>
            <select
              name="how"
              onChange={handleChange}
              className={errors.how ? "--error" : ""}
            >
              <option value="NA">Select...</option>
              <option value="Through a friend">Through a friend</option>
              <option value="Through school">Through school</option>
              <option value="Linkedin">Linkedin</option>
              <option value="Social media">Social media</option>
              <option value="Google/Search engine">Google/Search engine</option>
              <option value="Other">Other</option>
            </select>
            <div className="agree">
              <span
                className={
                  errors.agree
                    ? "error-msg is--active radio"
                    : "error-msg radio"
                }
              >
                *{" "}
              </span>
              <RadioButton
                isActive={values.agree}
                onClick={() =>
                  setValues((prev) => ({ ...prev, agree: !values.agree }))
                }
              />
              <h3>
                by checking this button you agree to ConnecMe2's{" "}
                <a
                  href="https://connecme2.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover-link"
                >
                  Terms and Conditions.
                </a>
              </h3>
            </div>
            <label
              className={
                Object.values(errors).some((v) => v)
                  ? "error-msg is--active"
                  : "error-msg"
              }
            >
              * This field is required
            </label>
            <div className="buttons">
              <button
                className="cta-button --grey"
                onClick={() => setPage("start")}
              >
                Back
              </button>
              <button className="cta-button" onClick={() => handleSubmit()}>
                Submit
              </button>
            </div>
          </div>
          <div
            className={page === "success" ? "success is--active" : "success"}
          >
            <img src={seekers} alt="" />
            <h2>
              A member of our team will reach out to you to schedule a call. We
              look forward to helping you find the career of your dreams!
            </h2>
            <h2>
              If you have any questions, feel free to email us at{" "}
              <a href="mailto:hello@cm2.co.uk" className="hover-link">
                hello@cm2.co.uk
              </a>
            </h2>
            <a className="cta-button" href="https://connecme2.com/seekers">
              visit site
            </a>
          </div>
        </>
      )}
    </div>
  );
}

export default App;
